import { useEffect, useState } from "react";
// for work_images
import work1 from "../assets/images/work_images/1.jpg";
import work2 from "../assets/images/work_images/2.jpg";
import work3 from "../assets/images/work_images/3.jpg";
import work4 from "../assets/images/work_images/4.jpg";
import work5 from "../assets/images/work_images/5.jpg";
import work6 from "../assets/images/work_images/6.jpg";
import work7 from "../assets/images/work_images/7.jpg";
import work8 from "../assets/images/work_images/8.jpg";
import work9 from "../assets/images/work_images/9.jpg";
// works small images
import workSmall1 from "../assets/images/work_images/small/1.jpg";
import workSmall2 from "../assets/images/work_images/small/2.jpg";
import workSmall3 from "../assets/images/work_images/small/3.jpg";
import workSmall4 from "../assets/images/work_images/small/4.jpg";
import workSmall5 from "../assets/images/work_images/small/5.jpg";
import workSmall6 from "../assets/images/work_images/small/6.jpg";
import workSmall7 from "../assets/images/work_images/small/7.jpg";
import workSmall8 from "../assets/images/work_images/small/8.jpg";
import workSmall9 from "../assets/images/work_images/small/9.jpg";
// blog post images
import blog6 from "../assets/images/blog_images/6.jpg";
import blog4 from "../assets/images/blog_images/4.jpg";
import blog2 from "../assets/images/blog_images/2.jpg";
import blog1 from "../assets/images/blog_images/1.jpg";
import blog3 from "../assets/images/blog_images/3.jpg";
import blog5 from "../assets/images/blog_images/5.jpg";
// blog image small
import blogSmall6 from "../assets/images/blog_images/small/6.jpg";
import blogSmall4 from "../assets/images/blog_images/small/4.jpg";
import blogSmall2 from "../assets/images/blog_images/small/2.jpg";
import blogSmall1 from "../assets/images/blog_images/small/1.jpg";
import blogSmall3 from "../assets/images/blog_images/small/3.jpg";
import blogSmall5 from "../assets/images/blog_images/small/5.jpg";

import img1 from "../assets/images/slider/ebuudspay-logo.svg";
import img2 from "../assets/images/slider/geraibayar-logo.png";
import img3 from "../assets/images/slider/brand-3.png";
import img4 from "../assets/images/slider/brand-4.png";
import img5 from "../assets/images/slider/brand-5.png";
//  icon use as img here
import icon from "../assets/images/icons/icon-1.svg";
import icon1 from "../assets/images/icons/icon-2.svg";
import icon2 from "../assets/images/icons/icon-3.svg";
import icon3 from "../assets/images/icons/icon-4.svg";
import icon4 from "../assets/images/icons/icon-5.svg";
import icon5 from "../assets/images/icons/icon-6.svg";
// contact image
import iconPhone from "../assets/images/contact/phone-call 1.png";
import iconEmail from "../assets/images/contact/email 1.png";
import iconMap from "../assets/images/contact/map 1.png";
import { CgNotes } from "react-icons/cg";
import { FaBlogger, FaRegUser, FaAward, FaServer } from "react-icons/fa";
import { AiOutlineHome } from "react-icons/ai";
import { FiCodesandbox } from "react-icons/fi";
import { RiContactsBookLine } from "react-icons/ri";
import { Link, useLocation } from "react-router-dom";

//MY CLIENT IMAGES
import ebudspayLogo from "../assets/images/my_clients/ebuudspay-logo.svg";
import pulsalapakLogo from "../assets/images/my_clients/pulsalapak-logo.png";
import gajahpayLogo from "../assets/images/my_clients/gajahpay-logo.png";
import grvLogo from "../assets/images/my_clients/garudavoucher-logo.png";
import geraibayarLogo from "../assets/images/my_clients/geraibayar-logo.png";
import gorolpayLogo from "../assets/images/my_clients/gorolpay-logo.png";
import kalipulsaLogo from "../assets/images/my_clients/kalipulsa-logo.png";
import mpiLogo from "../assets/images/my_clients/mpi-logo.png";
import mutasikilatLogo from "../assets/images/my_clients/mutasikilat-logo.svg";
import trustmeLogo from "../assets/images/my_clients/trus-me.jpeg";
import konterpulsaLogo from "../assets/images/my_clients/konter-pulsa-2.png";

import { MdOutlineBusinessCenter, MdOutlineSchool } from "react-icons/md";

const AllData = () => {
  const [check, setCheck] = useState(false);
  const [local, setLocal] = useState(localStorage.getItem("theme"));
  const [singleData, setSingleData] = useState({});
  const [isOpen, setIsOpen] = useState(false);

  // dark and light theme controls
  useEffect(() => {
    const themeValue = localStorage?.getItem("theme");

    if (!themeValue) {
      setCheck(false);
      localStorage.setItem("theme", "light");
    } else {
      themeValue === "dark" && setCheck(true);
      themeValue === "light" && setCheck(false);
    }

    localStorage?.getItem("theme") === "dark"
      ? document.documentElement.classList.add("dark")
      : document.documentElement.classList.remove("dark");
  }, []);

  // Create and light theme function
  const handleTheme = (value) => {
    if (value === "light") {
      setCheck(false);
      localStorage.setItem("theme", "light");
      setLocal("light");
    } else {
      setCheck(true);
      localStorage.setItem("theme", "dark");
      setLocal("dark");
    }
    localStorage?.getItem("theme") === "dark"
      ? document.documentElement.classList.add("dark")
      : document.documentElement.classList.remove("dark");
  };

  // fillter portfilo data
  const handleData = (text) => {
    if (text === "All") {
      setData(workItems);
    } else {
      const findData = workItems.filter((item) => item.tag === text);
      setData(findData);
    }
  };

  // find items for portfilo  modal open
  const handleModelData = (id) => {
    const find = workItems.find((item) => item?.id === id);
    setSingleData(find);
    setIsOpen(true);
  };

  // find items for Blogs  modal open
  const handleBlogsData = (id) => {
    const find = blogsData.find((item) => item?.id === id);
    setSingleData(find);
    setIsOpen(true);
  };

  // Active navlinks function
  function NavLink({
    to,
    className,
    activeClassName,
    inactiveClassName,
    ...rest
  }) {
    let location = useLocation();
    let isActive = location.pathname === to;
    let allClassNames =
      className + (isActive ? `${activeClassName}` : `${inactiveClassName}`);
    return <Link className={allClassNames} to={to} {...rest} />;
  }

  // Elements for protfilo section
  const workItems = [
    {
      id: "1",
      tag: "Payment Gateway",
      title: "Mitrapayment",
      img: mpiLogo,
      imgSmall: mpiLogo,
      bg: "#ffe9e9",
      client: "PT. Mitra Pembayaran Indonesia",
      langages: "Codeigniter, Laravel",
      link: "https://www.mitrapayment.com",
      linkText: "www.mitrapayment.com",
      description:
        "MPay Terbaik dan handal dilayanan payment. "+
        " Sistem MPay dangan koneksi ke banyak bank besar di indonesia." +
        " Memenuhi kebutuhan layanan payment baik VA, Dsiburst maupun online payment" +
        " dengan sistem yang handal cukup koneksikan sistem anda dengan kami." +
        " Monitor transaksi sangat mudah dengan dashboard realtime yang kami sediakan.",
    },
    {
      id: "2",
      tag: "Payment Gateway",
      title: "Gajahpay",
      img: gajahpayLogo,
      imgSmall: gajahpayLogo,
      bg: "#efe9ff",
      client: "Gajahpay",
      langages: "Laravel",
      link: "https://www.gajahpay.id",
      linkText: "www.gajahpay.id",
      description:
        "Gajahpay, the Most Complete Payment Gateway Solution in Indonesia for Your Business",
    },
    
    {
      id: "3",
      tag: "Topup Game, Pulsa, And PPOB",
      title: "Ebudspay",
      img: ebudspayLogo,
      imgSmall: ebudspayLogo,
      bg: "#E9FAFF",
      client: "Ebudspay",
      langages: "Codeigniter 3, HTML",
      link: "https://ebudspay.com",
      linkText: "www.ebudspay.com",
      description:
        "EbudsPay - Aplikasi Jualan Pulsa, Selain Pulsa tersedia juga Paket Data Internet, Paket Telpon dan SMS, Bayar Tagihan Bulanan, Topup Game Online, Topup E-Wallet dan Emoney, TV Prabayar DLL, Satu Aplikasi Untuk Semua Kebutuhan Digital Anda.",
    },
    {
      id: "4",
      tag: "Topup Game, Pulsa, And PPOB",
      title: "Pulsalapak",
      img: pulsalapakLogo,
      imgSmall: pulsalapakLogo,
      bg: "#FFF0F0",
      client: "Pulsalapak",
      langages: "Codeigniter 3, HTML",
      link: "https://pulsalapak.com",
      linkText: "www.pulsalapak.com",
      description:
        "Pulsalapak - Aplikasi Jualan Pulsa, Selain Pulsa tersedia juga Paket Data Internet, Paket Telpon dan SMS, Bayar Tagihan Bulanan, Topup Game Online, Topup E-Wallet dan Emoney, TV Prabayar DLL, Satu Aplikasi Untuk Semua Kebutuhan Digital Anda.",
    },
    {
      id: "5",
      tag: "Topup Game, Pulsa, And PPOB",
      title: "Garuda Voucher Indonesia",
      img: grvLogo,
      imgSmall: grvLogo,
      bg: "#FFFAE9",
      client: "Garuda Voucher Indonesia",
      langages: "Codeigniter",
      link: "https://app.grv.co.id/",
      linkText: "app.grv.co.id",
      description:
        "Garuda Voucher Indonesia adalah situs penjualan voucher game dan top up game termurah yang berpusat di Jakarta. Mengusung slogan “Sahabat Voucher Game Anda”, Garuda Voucher Indonesia hadir sebagai solusi bagi semua gamers di Tanah Air. Mau beli voucher game online apa saja, Garuda Voucher solusinya. Ada Garena, Gemscool, Megaxus, RF Return, Discord Nitro, dan masih banyak lagi. Mau top up game juga bisa. Ada Mobile Legends, PUBG Mobile, Ragnarok Mobile, Free Fire, Mobile Legends, Steam Wallet, Lost Saga Origin, Higgs Domino dan sebagainya. Hanya di Garuda Voucher, Anda akan mendapatkan harga terbaik dengan berbagai promo.",
    },
    
    {
      id: "6",
      tag: "Topup Game, Pulsa, And PPOB",
      title: "Geraibayar",
      img: geraibayarLogo,
      imgSmall: geraibayarLogo,
      bg: "#F4F4FF",
      client: "Geraibayar",
      langages: "Codeigniter",
      link: "https://www.geraibayar.com",
      linkText: "www.geraibayar.com",
      description:
        "Geraibayar - Aplikasi Jualan Pulsa, Selain Pulsa tersedia juga Paket Data Internet, Paket Telpon dan SMS, Bayar Tagihan Bulanan, Topup Game Online, Topup E-Wallet dan Emoney, TV Prabayar DLL, Satu Aplikasi Untuk Semua Kebutuhan Digital Anda.",
    },
    //  kalipulsaLogo 
    {
      id: "7",
      tag: "Topup Game, Pulsa, And PPOB",
      title: "Kalipulsa",
      img: kalipulsaLogo,
      imgSmall: kalipulsaLogo,
      bg: "#F4F4FF",
      client: "Kalipulsa",
      langages: "Codeigniter",
      link: "https://www.Kalipulsa.com",
      linkText: "www.kalipulsa.com",
      description:
        "Kalipulsa - Aplikasi Jualan Pulsa, Selain Pulsa tersedia juga Paket Data Internet, Paket Telpon dan SMS, Bayar Tagihan Bulanan, Topup Game Online, Topup E-Wallet dan Emoney, TV Prabayar DLL, Satu Aplikasi Untuk Semua Kebutuhan Digital Anda.",

    },
    {
      id: "8",
      tag: "Topup Game, Pulsa, And PPOB",
      title: "Konter Pulsa",
      img: konterpulsaLogo,
      imgSmall: konterpulsaLogo,
      bg: "#F4F4FF",
      client: "Konter Pulsa",
      langages: "Codeigniter",
      link: "https://konterpulsa.net/",
      linkText: "www.konterpulsa.net",
      description:
        "Konter Pulsa - Aplikasi Jualan Pulsa, Selain Pulsa tersedia juga Paket Data Internet, Paket Telpon dan SMS, Bayar Tagihan Bulanan, Topup Game Online, Topup E-Wallet dan Emoney, TV Prabayar DLL, Satu Aplikasi Untuk Semua Kebutuhan Digital Anda.",

    },
    {
      id: "9",
      tag: "Bank Mutation",
      title: "Mutasi kilat",
      img: mutasikilatLogo,
      imgSmall: mutasikilatLogo,
      bg: "#FFF0F8",
      client: "Mutasi Kilat",
      langages: "Codeigniter",
      link: "https://www.mutasikilat.com",
      linkText: "www.mutasikilat.com",
      description:
        "mutasikilat.com hadir sebagai solusi System validasi untuk berbagai pembayaran otomatis",
    },
    //
    {
      id: "10",
      tag: "Other Client",
      title: "50+ More Clients Trusted Me",
      img: trustmeLogo,
      imgSmall: trustmeLogo,
      bg: "#FFF0F8",
      client: "50+",
      langages: "-",
      link: "#",
      linkText: "-",
      description:
        "Thank you to 50+ clients or partners who have trusted your project or product to me",
    },
  ];
  const [data, setData] = useState(workItems);

  // Elements for Blogs section
  const blogsData = [
    {
      id: "1",
      img: blog1,
      imgSmall: blogSmall1,
      date: "177 April",
      category: "Inspiration",
      title: "How to Own Your Audience by Creating an Email List.",
      bg: "#FCF4FF",
      description:
        "Lorem ipsum dolor, sit amet consectetur adipisicing  elit. Fuga consequatur delectus porro sapiente molestias, magni quasi sed, enim corporis omnis doloremque soluta inventore dolorum conseqr quo obcaecati rerum sit non. Lorem ipsum dolor, sit amet consectetur adipisicing  elit. Fuga consequatur delectus porro sapiente molestias, magni quasi sed, enim corporis omnis doloremque soluta inventore dolorum consequuntur quo obcaecati rerum sit non. \n Lorem ipsum dolor, sit amet consectetur adipisicing  elit. Fuga consequatur delectus porro sapiente molestias, magni quasi sed, enim corporis omnis doloremque soluta inventore dolorum consetur quo obcaecati rerum sit non. Lorem ipsum dolor, sit amet consectetur adipisicing  elit. Fuga consequatur delectus porro sapiente molestias, magni quasi sed, sit amet consectetur adipisicing  elit. Fuga consequatur delectus porro sapiente molestias, magni quasi sed, enim corporis omnis doloremque soluta inventore dolorum consequuntur. \n Lorem ipsum dolor, sit amet consectetur adipisicing  elit. Fuga consequatur delectus porro sapiente molestias, magni quasi sed, enim corporis omnis doloremque soluta inventore dolorum consequuntur quo obcaecati rerum sit non. ",
    },
    {
      id: "4",
      img: blog4,
      imgSmall: blogSmall4,
      date: "000 April",
      category: "Inspiration",
      title: "Everything You Need to Know About Web Accessibility.",
      bg: "#EEFBFF",
      description:
        "Lorem ipsum dolor, sit amet consectetur adipisicing  elit. Fuga consequatur delectus porro sapiente molestias, magni quasi sed, enim corporis omnis doloremque soluta inventore dolorum conseqr quo obcaecati rerum sit non. Lorem ipsum dolor, sit amet consectetur adipisicing  elit. Fuga consequatur delectus porro sapiente molestias, magni quasi sed, enim corporis omnis doloremque soluta inventore dolorum consequuntur quo obcaecati rerum sit non. \n Lorem ipsum dolor, sit amet consectetur adipisicing  elit. Fuga consequatur delectus porro sapiente molestias, magni quasi sed, enim corporis omnis doloremque soluta inventore dolorum consetur quo obcaecati rerum sit non. Lorem ipsum dolor, sit amet consectetur adipisicing  elit. Fuga consequatur delectus porro sapiente molestias, magni quasi sed, sit amet consectetur adipisicing  elit. Fuga consequatur delectus porro sapiente molestias, magni quasi sed, enim corporis omnis doloremque soluta inventore dolorum consequuntur. \n Lorem ipsum dolor, sit amet consectetur adipisicing  elit. Fuga consequatur delectus porro sapiente molestias, magni quasi sed, enim corporis omnis doloremque soluta inventore dolorum consequuntur quo obcaecati rerum sit non. ",
    },
    {
      id: "2",
      img: blog2,
      imgSmall: blogSmall2,
      date: "21 April",
      category: "Web Design",
      title: "The window know to say beside you",
      bg: "#FFF0F0",
      description:
        "Lorem ipsum dolor, sit amet consectetur adipisicing  elit. Fuga consequatur delectus porro sapiente molestias, magni quasi sed, enim corporis omnis doloremque soluta inventore dolorum conseqr quo obcaecati rerum sit non. Lorem ipsum dolor, sit amet consectetur adipisicing  elit. Fuga consequatur delectus porro sapiente molestias, magni quasi sed, enim corporis omnis doloremque soluta inventore dolorum consequuntur quo obcaecati rerum sit non. \n Lorem ipsum dolor, sit amet consectetur adipisicing  elit. Fuga consequatur delectus porro sapiente molestias, magni quasi sed, enim corporis omnis doloremque soluta inventore dolorum consetur quo obcaecati rerum sit non. Lorem ipsum dolor, sit amet consectetur adipisicing  elit. Fuga consequatur delectus porro sapiente molestias, magni quasi sed, sit amet consectetur adipisicing  elit. Fuga consequatur delectus porro sapiente molestias, magni quasi sed, enim corporis omnis doloremque soluta inventore dolorum consequuntur. \n Lorem ipsum dolor, sit amet consectetur adipisicing  elit. Fuga consequatur delectus porro sapiente molestias, magni quasi sed, enim corporis omnis doloremque soluta inventore dolorum consequuntur quo obcaecati rerum sit non. ",
    },
    {
      id: "5",
      img: blog5,
      imgSmall: blogSmall5,
      date: "27 April",
      category: "Inspiration",
      title: "Top 10 Toolkits for Deep Learning in 2021.",
      bg: "#FCF4FF",
      description:
        "Lorem ipsum dolor, sit amet consectetur adipisicing  elit. Fuga consequatur delectus porro sapiente molestias, magni quasi sed, enim corporis omnis doloremque soluta inventore dolorum conseqr quo obcaecati rerum sit non. Lorem ipsum dolor, sit amet consectetur adipisicing  elit. Fuga consequatur delectus porro sapiente molestias, magni quasi sed, enim corporis omnis doloremque soluta inventore dolorum consequuntur quo obcaecati rerum sit non. \n Lorem ipsum dolor, sit amet consectetur adipisicing  elit. Fuga consequatur delectus porro sapiente molestias, magni quasi sed, enim corporis omnis doloremque soluta inventore dolorum consetur quo obcaecati rerum sit non. Lorem ipsum dolor, sit amet consectetur adipisicing  elit. Fuga consequatur delectus porro sapiente molestias, magni quasi sed, sit amet consectetur adipisicing  elit. Fuga consequatur delectus porro sapiente molestias, magni quasi sed, enim corporis omnis doloremque soluta inventore dolorum consequuntur. \n Lorem ipsum dolor, sit amet consectetur adipisicing  elit. Fuga consequatur delectus porro sapiente molestias, magni quasi sed, enim corporis omnis doloremque soluta inventore dolorum consequuntur quo obcaecati rerum sit non. ",
    },
    {
      id: "3",
      img: blog3,
      imgSmall: blogSmall3,
      date: "27 April",
      category: "Inspiration",
      title: "How to Own Your Audience by Creating an Email List.",
      bg: "#FCF4FF",
      description:
        "Lorem ipsum dolor, sit amet consectetur adipisicing  elit. Fuga consequatur delectus porro sapiente molestias, magni quasi sed, enim corporis omnis doloremque soluta inventore dolorum conseqr quo obcaecati rerum sit non. Lorem ipsum dolor, sit amet consectetur adipisicing  elit. Fuga consequatur delectus porro sapiente molestias, magni quasi sed, enim corporis omnis doloremque soluta inventore dolorum consequuntur quo obcaecati rerum sit non. \n Lorem ipsum dolor, sit amet consectetur adipisicing  elit. Fuga consequatur delectus porro sapiente molestias, magni quasi sed, enim corporis omnis doloremque soluta inventore dolorum consetur quo obcaecati rerum sit non. Lorem ipsum dolor, sit amet consectetur adipisicing  elit. Fuga consequatur delectus porro sapiente molestias, magni quasi sed, sit amet consectetur adipisicing  elit. Fuga consequatur delectus porro sapiente molestias, magni quasi sed, enim corporis omnis doloremque soluta inventore dolorum consequuntur. \n Lorem ipsum dolor, sit amet consectetur adipisicing  elit. Fuga consequatur delectus porro sapiente molestias, magni quasi sed, enim corporis omnis doloremque soluta inventore dolorum consequuntur quo obcaecati rerum sit non. ",
    },
    {
      id: "6",
      img: blog6,
      imgSmall: blogSmall6,
      date: "27 April",
      category: "Inspiration",
      title: "Everything You Need to Know About Web Accessibility.",
      bg: "#EEFBFF",
      description:
        "Lorem ipsum dolor, sit amet consectetur adipisicing  elit. Fuga consequatur delectus porro sapiente molestias, magni quasi sed, enim corporis omnis doloremque soluta inventore dolorum conseqr quo obcaecati rerum sit non. Lorem ipsum dolor, sit amet consectetur adipisicing  elit. Fuga consequatur delectus porro sapiente molestias, magni quasi sed, enim corporis omnis doloremque soluta inventore dolorum consequuntur quo obcaecati rerum sit non. \n Lorem ipsum dolor, sit amet consectetur adipisicing  elit. Fuga consequatur delectus porro sapiente molestias, magni quasi sed, enim corporis omnis doloremque soluta inventore dolorum consetur quo obcaecati rerum sit non. Lorem ipsum dolor, sit amet consectetur adipisicing  elit. Fuga consequatur delectus porro sapiente molestias, magni quasi sed, sit amet consectetur adipisicing  elit. Fuga consequatur delectus porro sapiente molestias, magni quasi sed, enim corporis omnis doloremque soluta inventore dolorum consequuntur. \n Lorem ipsum dolor, sit amet consectetur adipisicing  elit. Fuga consequatur delectus porro sapiente molestias, magni quasi sed, enim corporis omnis doloremque soluta inventore dolorum consequuntur quo obcaecati rerum sit non. ",
    },
  ];

  // Menu items for Homepage One
  const menuItem = [
    {
      id: "01",
      name: "Home",
      link: "/homePage",
      icon: <AiOutlineHome />,
    },
    {
      id: "02",
      name: "About",
      link: "/about",
      icon: <FaRegUser />,
    },
    {
      id: "06",
      name: "Resume",
      link: "/resume",
      icon: <CgNotes />,
    },
    {
      id: "03",
      name: "Clients",
      link: "/my-clients",
      icon: <FiCodesandbox />,
    },
    {
      id: "04",
      name: "Blogs",
      link: "/blogs",
      icon: <FaBlogger />,
    },
    {
      id: "05",
      name: "Contact",
      link: "/contact",
      icon: <RiContactsBookLine />,
    },
  ];

  // Menu items for Homepage Two
  const menuItemTwo = [
    {
      id: "01",
      name: "About",
      link: "/about",
      icon: <FaRegUser />,
    },
    {
      id: "02",
      name: "Resume",
      link: "/resume",
      icon: <CgNotes />,
    },
    {
      id: "03",
      name: "Clients",
      link: "/my-clients",
      icon: <FiCodesandbox />,
    },
    {
      id: "04",
      name: "Blogs",
      link: "/blogs",
      icon: <FaBlogger />,
    },
    {
      id: "05",
      name: "Contact",
      link: "/contact",
      icon: <RiContactsBookLine />,
    },
  ];

  // Slider image for Clients
  const sliderImg = [
    img1,
    img2,
    img3,
    img4,
    img5,
    img1,
    img2,
    img3,
    img4,
    img5,
  ];

  // experience items for about page
  const experienceArray = [
    {
      id: "1",
      icon: icon5,
      title: "Web Development",
      des: "Codeigniter 3/4, Laravel, React, NodeJs, Vuejs and others",
      color: "#269FFF",
      bg: "#F3FAFF",
    },
    {
      id: "2",
      icon: icon1,
      title: "App Development",
      des: "using the latest technology such as: React Native, Flutter, And Ionic",
      color: "#DDA10C",
      bg: "#FEFAF0",
    },
    {
      id: "3",
      icon: icon,
      title: "Service Server",
      des: "AWS, DigitalOcean, Google Cloud, Vultr, Upcloud, Hostinger, Niagahoster And Other Server",
      color: "#D566FF",
      bg: "#f4f5ff",
    },
    {
      id: "4",
      icon: icon4,
      title: "Managment",
      des: "Server management, Webhosting, DNS, CDN, and other",
      color: "#FF75D8",
      bg: "#FFF0F8",
    },
    // {
    //   id: "3",
    //   icon: icon2,
    //   title: "Photography",
    //   des: "Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam euismod volutpat.",
    //   color: "#8774FF",
    //   bg: "#FCF4FF",
    // },
    // {
    //   id: "4",
    //   icon: icon3,
    //   title: "Photography",
    //   des: "Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam euismod volutpat.",
    //   color: "#FF6080",
    //   bg: "#FFF4F4",
    // },
    
    
  ];

  // Resume items for Resume page
  const resumeArray = [
    {
      type: "Education",
      icon: MdOutlineSchool,
      id: "01",
      date: "2015 - 2016",
      title: "SMK Teknology Dan Grafika ",
      place: "Kota Tangerang, Indonesia",
      bg: "#FFF4F4",

      id1: "02",
      date1: "2012 - 2013",
      title1: "SMP Negeri 14 Tangerang",
      place1: "Kota Tangerang, Indonesia",
      bg1: "#FFF1FB",

      id2: "03",
      date2: "2005 - 2011",
      title2: "SDN Panunggangan 4",
      place2: "Kota Tangerang, Indonesia",
      bg2: "#FFF4F4",
    },
    {
      type: "Experience",
      icon: MdOutlineBusinessCenter,
      id: "04",
      date: "2020 - Present",
      title: "IT Development",
      place: "PT. Mitra Pembayaran Indonesia",
      bg: "#EEF5FA",

      id1: "05",
      date1: "2019 - 2020",
      title1: "Freelance",
      place1: "Freelance IT Web Development",
      bg1: "#F2F4FF",

      id2: "06",
      date2: "2019 - 2019",
      title2: "Sales Marketing",
      place2: "PT. Rama Indonesia",
      bg2: "#EEF5FA",
    },
    {
      type: "Awards",
      icon: FaAward,
      id: "07",
      date: "2015-2017",
      title: "  Graphic Designer",
      place: "Web Graphy, Los Angeles, CA",
      bg: "#FCF4FF",

      id1: "08",
      date1: "2014 - 2015",
      title1: "Jr. Web Developer",
      place1: "Creative Gigs.",
      bg1: "#FCF9F2",

      id2: "09",
      date2: "2015-2017",
      title2: "Best Freelancer",
      place2: "Fiver & Upwork Level 2 & Top Rated",
      bg2: "#FCF4FF",
    },
  ];

  // Working Skills items for Resume page
  const lineArray = [
    {
      id: "01",
      color: "#9272D4",
      name: "Framework Codeigniter",
      number: "95",
    },
    {
      id: "02",
      color: "#FF6464",
      name: "Framework Laravel",
      number: "75",
    },
    {
      id: "03",
      color: "#5185D4",
      name: "HTML",
      number: "95",
    },
    {
      id: "03",
      color: "#CA56F2",
      name: "Javascript, React, Nodejs",
      number: "55",
    },
  ];

  // Personal information for contact pages
  const contactArray = [
    {
      id: "01",
      icon: iconPhone,
      title: "Phone ",
      item1: "+452 666 386",
      item2: "+452 666 386",
      bg: "#FCF4FF",
    },
    {
      id: "02",
      icon: iconEmail,
      title: "Email ",
      item1: "support@gmail.com",
      item2: "example@gmail.com",
      bg: "#EEFBFF",
    },
    {
      id: "03",
      icon: iconMap,
      title: "Address ",
      item1: "Maount View, Oval",
      item2: "Road, New York, USA",
      bg: "#F2F4FF",
    },
  ];

  return {
    handleTheme,
    check,
    local,
    handleData,
    data,
    singleData,
    handleModelData,
    isOpen,
    setIsOpen,
    blogsData,
    handleBlogsData,
    menuItem,
    NavLink,
    menuItemTwo,
    experienceArray,
    sliderImg,
    resumeArray,
    lineArray,
    contactArray,
  };
};

export default AllData;
